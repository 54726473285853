body, html {
    margin: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

*, :after, :before {
    box-sizing: inherit;
    -webkit-tap-highlight-color: transparent;
    -moz-tap-highlight-color: transparent;
}

.flex-center, .items-center {
    align-items: center;
}

.flex-center, .justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.wrap {
    flex-wrap: wrap!important;
}

.no-wrap {
    flex-wrap: nowrap!important;
}

.column, .flex, .row {
    display: flex;
    flex-wrap: wrap;
}

.q-pt-md {
    padding-top: 16px;
}

.text-grey {
    color: #666!important;
}

.block {
  display: block;
}

.hide-scrollbar {
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.desktop-text {
  display: none;
}

// Desktop view of scrollbar
@media (min-width:801px)  { 
  ::-webkit-scrollbar {
    height: 5px !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(202, 202, 202);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  } 

  .desktop-text {
    display: block;
    font-size: 95%;
    padding: 30px 20px 20px 0;
    margin-bottom: -15px;
    text-align: center;
    opacity: 0.4;
  }
}

.non-selectable {
    -webkit-user-select: none!important;
    -moz-user-select: none!important;
    -ms-user-select: none!important;
    user-select: none!important;
}

.col {
    width: 100%;
    min-width: 0;
    // max-width: 100%;
}

.focus-helper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    border-radius: inherit;
    opacity: 0;
    transition: background-color 0.3s cubic-bezier(0.25,0.8,0.5,1),opacity 0.4s cubic-bezier(0.25,0.8,0.5,1);
}

.focus-helper:before {
    background: #000;
}

focus-helper:after {
    background: #fff;
}

.btn {
    display: inline-flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;
    outline: 0;
    border: 0;
    vertical-align: middle;
    padding: 0;
    font-size: 14px;
    line-height: 1.715em;
    text-decoration: none;
    color: inherit;
    background: transparent;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    width: auto;
    height: auto;
}

.focusable, .hoverable {
    outline: 0;
}

body {
    font-family: Manrope,sans-serif;
    font-weight: 600;
    min-width: 100px;
    min-height: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    line-height: 1.5;
    font-size: 14px;
}

#layout {
    position: relative;
    min-height: 667px;
    width: 100%;

    header {
        margin: 0 auto;
        max-width: 800px;

        &.fixed-top {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: 2000;
        }

        #toolbar {
            padding-top: 16px;
            position: relative;
            padding: 0 12px;
            min-height: 80px;
            width: 100%;

            img.logo {
                max-width: 210px;
            }
        }
    }

    .container {
        padding-top: 10vh;
        max-width: 800px;
        left: 0;
        right: 0;
        margin: auto;

        main {
            min-height: 504px;
            position: relative;
            display: block;
            
            nav {
                position: sticky;
                top: 0;
                z-index: 4;
                margin-top: 8px;
                background-color: white;
                transition: color .3s,background-color .3s;
            }

            .nav-tabs {
                align-self: stretch;
                overflow: auto;
                flex: 1 1 auto;

                .nav-tab {
                    min-height: 38px;
                    border-radius: 11px;
                    text-transform: none;
                    margin-top: 16px;
                    margin-bottom: 16px;
                    position: relative;
                    cursor: pointer!important;
                    -webkit-user-select: none!important;
                    -moz-user-select: none!important;
                    -ms-user-select: none!important;
                    user-select: none!important;
                    align-self: stretch;
                    padding: 0 16px;
                    min-height: 38px;
                    transition: color .3s,background-color .3s;
                    white-space: nowrap;
                    color: inherit;
                    text-decoration: none;

                    .nav-tab-content {
                        padding-top: 0;
                        padding-bottom: 0;
                        position: relative;
                        align-self: stretch;
                        height: inherit;
                        padding: 4px 0;
                        min-width: 40px;
                    }

                    .nav-tab-label {
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 1.715em;
                    }

                    &.active {
                        color: #fff;
                        background-color: #f87227;
                        will-change: filter;
                        -webkit-filter: drop-shadow(0 5px 6px rgba(0,0,0,0.13));
                        filter: drop-shadow(0 5px 6px #00000020);
                        
                    }

                    &.check {
                      color: #fff;
                      background-color: #5cb85c;                      ;
                      will-change: filter;
                      -webkit-filter: drop-shadow(0 5px 6px rgba(0,0,0,0.13));
                      filter: drop-shadow(0 5px 6px #00000020);
                      
                  }
            
                }

                .nav-tab:first-of-type {
                    margin-left: 16px;
                }

                a {
                  color: inherit; /* blue colors for links too */
                  text-decoration: inherit; /* no underline */
                }

            }
            
        }

        #content-container {
            margin-top: -40px;
            padding-bottom: 16px;
            padding-left: 8px;
            padding-right: 8px;

            h2.category-name {
                font-size: 15px;
                line-height: 1.6;
                font-weight: 700;
                letter-spacing: 0;
                text-transform: uppercase;
                margin-top: 15px;
                margin-bottom: 0px;
                padding-left: 15px;
                padding-right: 15px;
                color: #666!important;
            }

            // marginTop -120px for first header in .js code
            h2.category-name:before {
              display: block; 
              content: " "; 
              margin-top: -80px; 
              height: 80px; 
              visibility: hidden; 
            }

            .product {
                height: auto;
                width: 100%;
                min-width: 0;
                max-width: 100%;
                position: relative;
                border-radius: 0!important;
                padding-top: 20px;
                padding-bottom: 10px;
                flex: 0 0 auto;
                box-shadow: none!important;
                vertical-align: top;
                background: #fff;
            }

            .product:after {
                content : "";
                position: absolute;
                left    : 8%;
                bottom  : 0;
                height  : 2px;
                width   : 84%;  /* or 100px */
                border-bottom:1px solid #e9e9e9;
              }

            .product>div:last-child, .product>img:last-child {
                border-bottom: 0;
                border-bottom-left-radius: inherit;
                border-bottom-right-radius: inherit;
            }

            .product>div:first-child, .product>img:first-child {
                border-top: 0;
                border-top-left-radius: inherit;
                border-top-right-radius: inherit;
            }

            .product>div {
                border-left: 0;
                border-right: 0;
                box-shadow: none;
                border-radius: 0!important;
                flex-wrap: nowrap;
                position: relative;
                border-top: 0;
                border-bottom: 0;
            }

            .product-container>div:not(:last-child), 
            .product-container>img:not(:last-child) {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            .product-container>div:not(:first-child), 
            .product-container>img:not(:first-child) {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            .product-container>div {
                border-top: 0;
                border-bottom: 0;
                box-shadow: none;
                padding-left: 8px;
                padding-right: 8px;
                padding-bottom: 17px;

                .product-photo {
                    margin-top:10px;
                    max-width: 80px;
                    max-height: 80px;
                    width: 100px;
                    border-radius: 11px;
                    overflow: hidden!important;
                    display: inline-block;
                    vertical-align: middle;
                    position: relative;   
                    -webkit-box-shadow: 4px 10px 12px -2px rgba(0,0,0,0.07); 
                    box-shadow: 4px 10px 12px -2px rgba(0,0,0,0.07);           

                    .photo {
                        background-size: cover;
                        background-position: 50% 50%;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        position: absolute;
                        border-radius: inherit;
                        background-repeat: no-repeat;
                    }
                }
            }

            
            .info {
                padding-left: 12px !important;
                padding-right: 8px;
                padding-top: 0;
                padding-bottom: 0;
                flex: 10000 1 0%;
                position: relative;
                box-shadow: none;
            }

            h6.product-title {
                line-height: 1.6rem;
                font-weight: 700;
                letter-spacing: 0;
                margin-bottom: 0px;
                margin-top: 0;
                font-size: 1.15rem;
            }

            p.product-style {
                font-size: 14px;
                font-weight: 500;
                color: #666!important;
                margin: 0 0 6px;
                position: relative;
            }

            p.product-price {
                font-size: 15px;
                line-height: 1.6;
                height: -webkit-fit-content;
                height: -moz-fit-content;
                height: fit-content;
                font-weight: 700;
                margin-bottom: 0;
                margin-top: -3px;
            }

            .product-annotation {
                // padding: 0 15px;
                font-weight: 500;
                font-size: 11px;
                padding: 0;
                margin: 0 0 15px 0;
            }
        }

        .expanded {
          // padding-top: 10px;
          // margin-bottom: 10px;
          // padding-bottom: 10px;
        }

        .expanded-info {
          padding-left: 0 !important;
        }

    }

    .shadow {
        position: sticky;
        display: block;
        top: 70px;
        background: linear-gradient(hsla(0,0%,69.4%,0.4),hsla(0,0%,82.4%,0.224527) 30%,hsla(0,0%,100%,0));
        height: 20px;
        z-index: 2;
    }

    .cover {
        position: relative;
        top: -30px;
        background: #fff;
        width: 100%;
        height: 30px;
        z-index: 3;
    }
}

@mixin transition {
    @for $i from 1 through 50 {
      &:nth-child(#{$i}) {
        transition: transform 1s #{$i * .08}s cubic-bezier(.29,1.4,.44,.96);
      }
    }
  }
  
  @mixin alpha-attribute($attribute, $color, $background) {
    $percent: alpha($color) * 100%;
    $opaque: opacify($color, 1);
    $solid-color: mix($opaque, $background, $percent);
    #{$attribute}: $solid-color;
    #{$attribute}: $color;
  }

$main: #f87227;
$text: #FEFEFE;
$contrast: darken($main, 7%);
  
.drawer-list {
    position: fixed;
    right: 0;
    top: 0;
    height: 50vh;
    width: 90vw;
    transform: translate(100vw, 0);
    /* ie workaround */
    -ms-transform: translatex(-100vw);
    box-sizing: border-box;
    pointer-events: none;
    padding-top: 125px;
    transition: width 475ms ease-out, transform 450ms ease, border-radius .8s .1s ease;
    border-bottom-left-radius: 100vh;
    @include alpha-attribute('background-color', rgba($contrast, 0.8), rgb(255, 255, 255));
    @media (min-width: 768px) {
      width: 30vw;
    }
    @media (min-width: 1100px) {
      width: 25vw;
    }
    @media (min-width: 1550px) {
      width: 15vw;
    }
    ul {
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
        overflow: auto;
        overflow-x: hidden;
        pointer-events: auto;
      }
      li {
        list-style: none;
        text-transform: uppercase;
        pointer-events: auto;
        white-space: nowrap;
        box-sizing: border-box;
        transform: translatex(100vw);
        /* ie workaround */
        -ms-transform: translatex(-100vw);
        &:last-child {
          margin-bottom: 2em;
        }
        a {
          text-decoration: none;
          color: $text;
          text-align: center;
          display: block;
          padding: 0.5rem;
          font-size: calc(24px - .5vw);
          @media (min-width: 768px) {
            text-align: right;
            padding: .5rem;
          }
          &:hover {
            cursor: pointer;
            @include alpha-attribute('background-color', rgba($main, 0.5), rgb(255, 255, 255));
          }
        }
      }
    }

    
input.hamburger {
    display: none;
    &:checked {
      & ~ .drawer-list {
         transform: translatex(0);
          border-bottom-left-radius: 10vh;
          li {
            transform: translatex(0);
            @include transition;
            a {
              padding-right: 15px;
            }
          }
      }
      & ~ label {
        > i {
          background-color: transparent;
          transform: rotate(90deg);
          &:before {
            transform: translate(-50%, -50%) rotate(45deg);
          }
          &:after {
            transform: translate(-50%, -50%) rotate(-45deg);
          }
        }
        close {
          color: $text;
          width: 100%;
        }
        open {
          color: rgba(0, 0, 0, 0);
          width: 0;
        }
      }
    }
  }
  
  label.hamburger {
    position: relative;
    display: block;
    height: 35px;
    width: 35px;
    &:hover {
      cursor: pointer;
    }
    text {
      close,
      open {
        text-transform: uppercase;
        font-size: .68em;
        text-align: center;
        position: absolute;
        transform: translateY(40px);
        text-align: center;
        overflow: hidden;
        transition: width .25s .35s, color .45s .35s;
      }
      close {
        color: rgba(0, 0, 0, 0);
        right: 0;
        width: 0;
      }
      open {
        color: #bdbdbd;
        width: 100%;
      }
    }
    > i {
      position: absolute;
      width: 70%;
      height: 3px;
      top: 50%;
      left: 15%;
      background-color: #bdbdbd;
      pointer-events: auto;
      transition-duration: .35s;
      transition-delay: .35s;
      &:before,
      &:after {
        position: absolute;
        display: block;
        width: 100%;
        height: 3px;
        left: 50%;
        background-color: #bdbdbd;
        content: "";
        transition: transform 0.35s;
        transform-origin: 50% 50%;
      }
      &:before {
        transform: translate(-50%, -7px);
      }
      &:after {
        transform: translate(-50%, 7px);
      }
    }
  }
  
  label.hamburger {
    position: absolute;
    top: 20px;
    right: 15px;
    z-index: 2;
  }

  .orange-text {
    color: #f87227;
  }

  @-webkit-keyframes spinner {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}

  @keyframes spinner {
    to {
      transform: rotate(360deg) /* rtl:ignore */;
    }
  }

  .spinner {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -0.125em;
    border: 0.25em solid;
    border-right: 0.25em solid transparent;
    border-radius: 50%;
    animation: spinner-border .75s linear infinite;
  }
  